<template>
  <sidenav :orientation="orientation" :class="curClasses">
    <!-- Inner -->
    <div
      class="sidenav-inner"
      :class="{ 'py-1': orientation !== 'horizontal' }"
    >
      <sidenav-router-link
        v-if="ability.can('read', 'Dashboard')"
        icon="fas fa-th"
        to="/"
        :exact="true"
        >Dashboard</sidenav-router-link
      >

      <sidenav-menu
        v-if="ability.can('read', 'Lead') || ability.can('create', 'Lead')"
        icon="ion ion-md-radio-button-on"
        :active="isMenuActive('/leads')"
        :open="isMenuOpen('/leads')"
      >
        <template slot="link-text"
          >Leads
          <label
            v-show="$store.state.newLeads"
            class="bg-primary text-white font-weight-bold align-text-top ml-2"
            style="
              padding: 0px 6px;
              text-align: center;
              width: auto;
              border-radius: 1rem;
            "
            >{{ $store.state.newLeads }}</label
          >
        </template>
        <sidenav-router-link
          to="/leads"
          :exact="true"
          v-if="ability.can('read', 'Lead')"
          @click="refreshNewLeads()"
          >View Leads</sidenav-router-link
        >
        <sidenav-router-link
          to="/leads/create"
          :exact="true"
          v-if="ability.can('create', 'Lead')"
          >Add Lead</sidenav-router-link
        >
      </sidenav-menu>

      <sidenav-menu
        icon="ion ion-ios-contacts"
        :active="isMenuActive('/crm')"
        :open="isMenuOpen('/crm')"
        v-if="
          ability.can('read', 'Contact') ||
          ability.can('read', 'Band') ||
          ability.can('read', 'Company')
        "
      >
        <template slot="link-text">Contact Manager</template>
        <sidenav-router-link
          to="/crm/contacts"
          :exact="true"
          v-if="ability.can('read', 'Contact')"
          >Contact</sidenav-router-link
        >
        <sidenav-router-link
          to="/crm/band-groups"
          :exact="true"
          v-if="ability.can('read', 'Band')"
          >Artist / Group</sidenav-router-link
        >
        <sidenav-router-link
          to="/crm/company"
          :exact="true"
          v-if="ability.can('read', 'Company')"
          >Company</sidenav-router-link
        >
      </sidenav-menu>

      <sidenav-menu
        v-if="
          ability.can('read', 'Project') ||
          ability.can('create', 'Project') ||
          ability.can('create', 'Project-file-delivery')
        "
        icon="ion ion-md-options"
        :active="isMenuActive('/projects')"
        :open="isMenuOpen('/projects')"
      >
        <template slot="link-text">Projects</template>
        <sidenav-router-link
          to="/projects"
          :exact="true"
          v-if="
            ability.can('read', 'Project') ||
            ability.can('create', 'Project-file-delivery')
          "
          >View Projects</sidenav-router-link
        >
        <sidenav-router-link
          to="/projects/create"
          :exact="true"
          v-if="ability.can('create', 'Project')"
          >Add Project</sidenav-router-link
        >
      </sidenav-menu>

      <sidenav-router-link
        icon="fas fa-tasks"
        to="/tasks"
        :exact="true"
        v-if="ability.can('read', 'Task')"
        >To Dos</sidenav-router-link
      >

      <sidenav-menu
        icon="ion ion-ios-megaphone"
        v-if="
          ability.can('read', 'Event') ||
          ability.can('read', 'Booking') ||
          ability.can('read', 'Quote') ||
          ability.can('read', 'Invoice')
        "
        :active="isMenuActive('/marketing')"
        :open="isMenuOpen('/marketing')"
      >
        <template slot="link-text">Marketing</template>
        <sidenav-router-link
          to="/marketing/events"
          :exact="true"
          v-if="ability.can('read', 'Event')"
          >Events</sidenav-router-link
        >
      </sidenav-menu>

       <sidenav-router-link
        icon="ion ion-ios-calendar"
        to="/bookings"
        :exact="true"
        v-if="ability.can('read', 'Booking')"
        >Calendar</sidenav-router-link
      >

      <sidenav-menu
        v-if="
          ability.can('read', 'Quote') ||
          ability.can('create', 'Quote') ||
          ability.can('read', 'Invoice') ||
          ability.can('create', 'Invoice')
        "
        icon="fas fa-money-bill-wave"
        :active="isMenuActive('/quotes') || isMenuActive('/invoices')"
        :open="isMenuOpen('/quotes') || isMenuOpen('/invoices')"
      >
        <template slot="link-text">Financials</template>
        <sidenav-router-link
          v-if="ability.can('read', 'Quote')"
          to="/quotes"
          :exact="true"
          >View Quotes</sidenav-router-link
        >
        <sidenav-router-link
          v-if="ability.can('create', 'Quote')"
          to="/quotes/create"
          :exact="true"
          >Add Quotes</sidenav-router-link
        >
        <sidenav-router-link
          v-if="ability.can('read', 'Invoice')"
          to="/invoices"
          :exact="true"
          >View Invoices</sidenav-router-link
        >
        <sidenav-router-link
          v-if="ability.can('create', 'Invoice')"
          to="/invoices/create"
          :exact="true"
          >Add Invoices</sidenav-router-link
        >
      </sidenav-menu>

      <sidenav-router-link
        icon="fas fa-microphone-alt"
        to="/equipment"
        :exact="true"
        v-if="ability.can('read', 'Equipment')"
        >Equipment</sidenav-router-link
      >

      <sidenav-menu
        icon="ion ion-md-podium"
        :active="
          isMenuActive('/reports') || isMenuActive('/settings/report-builder')
        "
        :open="isMenuOpen('/reports') || isMenuOpen('/settings/report-builder')"
        v-if="ability.can('read', 'Report')"
      >
        <template slot="link-text">Reports</template>
        <sidenav-router-link
          v-if="!isSpotifySite"
          to="/reports/active-leads"
          :exact="true"
          >Active Lead Pipeline
        </sidenav-router-link>
        <sidenav-router-link
          v-if="!isSpotifySite"
          to="/reports/converted-leads"
          :exact="true"
          >Converted Leads
        </sidenav-router-link>
        <sidenav-router-link
          v-if="!isSpotifySite"
          to="/reports/closed-lost-leads"
          :exact="true"
          >Closed Lost Leads</sidenav-router-link
        >
        <sidenav-router-link
          v-if="!isSpotifySite"
          to="/reports/revenue"
          :exact="true"
          >Revenue</sidenav-router-link
        >
        <sidenav-router-link
          v-if="!isSpotifySite"
          to="/reports/revenue-monthly"
          :exact="true"
          >Monthly Revenue</sidenav-router-link
        >
        <sidenav-router-link
          v-if="!isSpotifySite"
          to="/reports/open-invoices"
          :exact="true"
          >Open Invoices</sidenav-router-link
        >
        <sidenav-router-link
          v-if="!isSpotifySite"
          to="/reports/past-due-invoices"
          :exact="true"
          >Past Due Invoices</sidenav-router-link
        >
        <sidenav-router-link to="/reports/booking" :exact="true"
          >Bookings</sidenav-router-link
        >
        <sidenav-router-link to="/reports/session-report" :exact="true"
          >Sessions</sidenav-router-link
        >
        <sidenav-router-link
          v-if="!isSpotifySite"
          to="/reports/referrals"
          :exact="true"
          >Referrals</sidenav-router-link
        >
        <sidenav-router-link
          v-if="!isSpotifySite"
          to="/reports/event-roi"
          :exact="true"
          >Event ROI</sidenav-router-link
        >
        <sidenav-router-link
          v-if="!isSpotifySite"
          to="/reports/time-tracking"
          :exact="true"
          >Time Tracking</sidenav-router-link
        >
        <sidenav-router-link to="/reports/room-utilization" :exact="true">
          Room Utilization (Hours)
        </sidenav-router-link>
        <sidenav-router-link to="/reports/daily-room-utilization" :exact="true">
          Room Utilization (Days)
        </sidenav-router-link>
        <sidenav-router-link to="/reports/room-rates-and-labor">
          Room Rates & Labor
        </sidenav-router-link>
        <!-- <sidenav-router-link to="/reports/equipment-tracking" > Equipment Tracking </sidenav-router-link> -->

        <sidenav-router-link
          to="/settings/report-builder"
          :exact="true"
          v-if="
            ability.can('read', 'Setting-company') ||
            ability.can('update', 'Setting-company')
          "
          >Report Builder</sidenav-router-link
        >
      </sidenav-menu>

      <sidenav-divider class="mb-1" />
      <div
        class="sidenav-inner"
        v-if="
          roles.includes('Admin') ||
          ability.can('read', 'Setting-company') ||
          ability.can('update', 'Setting-company') ||
          ability.can('read', 'Setting-whitelabel') ||
          ability.can('update', 'Setting-whitelabel') ||
          ability.can('read', 'Room') ||
          ability.can('update', 'Room') ||
          ability.can('read', 'Export') ||
          ability.can('read', 'Import') ||
          ability.can('read', 'User') ||
          ability.can('update', 'User') ||
          ability.can('create', 'User') ||
          ability.can('delete', 'User') ||
          ability.can('read', 'Setting-picklist') ||
          ability.can('update', 'Setting-picklist') ||
          ability.can('read', 'Setting-equipmentCategory') ||
          ability.can('update', 'Setting-equipmentCategory')
        "
      >
        <sidenav-header class="small font-weight-semibold"
          >Administration</sidenav-header
        >

        <sidenav-menu
          icon="ion ion-ios-people"
          :active="isMenuActive('/user')"
          :open="isMenuOpen('/user')"
          v-if="
            p == 1 &&
            (ability.can('read', 'User') || ability.can('update', 'User'))
          "
        >
          <template slot="link-text">Users</template>
          <sidenav-router-link
            to="/user/list"
            :exact="true"
            v-if="ability.can('read', 'User')"
            >View Users</sidenav-router-link
          >
          <sidenav-router-link
            to="/user/create"
            :exact="true"
            v-if="ability.can('create', 'User')"
            >Add User</sidenav-router-link
          >
        </sidenav-menu>

        <sidenav-menu
          icon="ion ion-md-cog"
          :active="
            isMenuActive('/settings') ||
            isMenuActive('/tools') ||
            isMenuActive('/support-access')
          "
          :open="
            isMenuOpen('/settings') ||
            isMenuOpen('/tools') ||
            isMenuOpen('/support-access')
          "
          v-if="
            roles.includes('Admin') ||
            ability.can('read', 'Setting-company') ||
            ability.can('update', 'Setting-company') ||
            ability.can('read', 'Setting-whitelabel') ||
            ability.can('update', 'Setting-whitelabel') ||
            ability.can('read', 'Setting-picklist') ||
            ability.can('update', 'Setting-picklist') ||
            ability.can('read', 'Setting-equipmentCategory') ||
            ability.can('update', 'Setting-equipmentCategory') ||
            ability.can('read', 'Setting-CustomField') ||
            ability.can('update', 'Setting-CustomField') ||
            ability.can('read', 'Setting-Coupon') ||
            ability.can('update', 'Setting-Coupon') ||
            ability.can('read', 'Room') ||
            ability.can('update', 'Room')
          "
        >
          <template slot="link-text">Settings</template>
          <sidenav-router-link
            to="/settings/white-label"
            :exact="true"
            v-if="ability.can('read', 'Setting-whitelabel')"
            >White Label</sidenav-router-link
          >
          <sidenav-router-link
            to="/settings/room"
            :exact="true"
            v-if="ability.can('read', 'Room') && p == 1"
            >Room Management</sidenav-router-link
          >
          <sidenav-router-link
            to="/settings/company"
            :exact="true"
            v-if="
              ability.can('read', 'Setting-company') ||
              ability.can('update', 'Setting-company')
            "
            >Company</sidenav-router-link
          >

          <sidenav-menu
          icon=""
          :active="isMenuActive('/settings/picklists') || isMenuActive('/settings/custom-field') || isMenuActive('/settings/equipment-categories')"
          :open="isMenuOpen('/settings/picklists') || isMenuOpen('/settings/custom-field') || isMenuOpen('/settings/equipment-categories')"
          
        >
            <template slot="link-text">Custom Fields</template>
            <sidenav-router-link
              to="/settings/picklists"
              :exact="true"
              v-if="
                ability.can('read', 'Setting-picklist') ||
                ability.can('update', 'Setting-picklist')
              "
              >Picklists</sidenav-router-link
            >
            <sidenav-router-link
              to="/settings/custom-field"
              :exact="true"
              v-if="
                p == 1 &&
                (ability.can('read', 'Setting-CustomField') ||
                  ability.can('update', 'Setting-CustomField'))
              "
              >Custom Fields</sidenav-router-link
            >

            <sidenav-router-link
              to="/settings/equipment-categories"
              :exact="true"
              v-if="
                ability.can('read', 'Setting-equipmentCategory') ||
                ability.can('update', 'Setting-equipmentCategory')
              "
              >Equipment Categories</sidenav-router-link
            >
            <sidenav-router-link
              to="/settings/pricelists"
            >
              Price List
            </sidenav-router-link>
          </sidenav-menu>

          <sidenav-router-link 
            to="/settings/coupons" :exact="true"
            v-if="
              ability.can('read', 'Setting-Coupon') ||
              ability.can('update', 'Setting-Coupon')
            "
            >Coupons</sidenav-router-link
          >

          <sidenav-router-link
            to="/settings/integrations"
            :exact="true"
            v-if="
              ability.can('read', 'Setting-company') ||
              ability.can('update', 'Setting-company')
            "
            >Integrations</sidenav-router-link
          >
          <sidenav-router-link
            to="/settings/forms"
            :exact="true"
            v-if="
              ability.can('read', 'Setting-Form') ||
              ability.can('update', 'Setting-Form')
            "
            >Forms</sidenav-router-link
          >
          <sidenav-router-link
            to="/tools/imports"
            :exact="true"
            v-if="ability.can('read', 'Import')"
            >Data Import</sidenav-router-link
          >
          <sidenav-router-link
            to="/tools/exports"
            :exact="true"
            v-if="ability.can('read', 'Export')"
            >Data Export</sidenav-router-link
          >
          <sidenav-router-link
            to="/support-access"
            v-if="roles.includes('Admin')"
            :exact="true"
            >Support Access</sidenav-router-link
          >
        </sidenav-menu>

        <!--<sidenav-menu icon="ion ion-md-cube" :active="isMenuActive('/tools')" :open="isMenuOpen('/tools')" v-if="ability.can('read', 'Import') || ability.can('read', 'Export')">
              <template slot="link-text">Data Import/Export</template>

          </sidenav-menu>-->
      </div>
    </div>
  </sidenav>
</template>

<script>
import {
  Sidenav,
  // SidenavLink,
  SidenavRouterLink,
  SidenavMenu,
  SidenavHeader,
  // SidenavBlock,
  SidenavDivider,
} from "@/vendor/libs/sidenav";

export default {
  name: "app-layout-sidenav",
  components: {
    Sidenav,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavDivider,
  },

  data: () => ({
    isSpotifySite: false,
  }),

  props: {
    orientation: {
      type: String,
      default: "vertical",
    },
  },

  created() {
    this.detectSpotify();
    this.checkNewLeads();
    console.log("roles e");
    console.log(this.roles);
    console.log(this.roles.includes("Admin"));
  },

  computed: {
    p() {
      return localStorage.getItem("website_p");
    },
    roles() {
      return localStorage.getItem("roles");
    },
    ability() {
      return window.ability;
    },
    curClasses() {
      let bg = this.layoutSidenavBg;

      if (
        this.orientation === "horizontal" &&
        (bg.indexOf(" sidenav-dark") !== -1 ||
          bg.indexOf(" sidenav-light") !== -1)
      ) {
        bg = bg
          .replace(" sidenav-dark", "")
          .replace(" sidenav-light", "")
          .replace("-darker", "")
          .replace("-dark", "");
      }

      return (
        `bg-${bg} ` +
        (this.orientation !== "horizontal"
          ? "layout-sidenav"
          : "layout-sidenav-horizontal container-p-x flex-grow-0")
      );
    },
  },

  methods: {
    refreshNewLeads() {
      alert("in refreshNewLeads");
      this.checkNewLeads();
    },
    async checkNewLeads() {
      // alert('in checkNewLeads');

      try {
        const response = await window.axios.post("/api/leads/new");
        this.$store.state.newLeads = response.data;
        if (this.$store.state.newLeads > 0) return this.$store.state.newLeads;
        else return false;
      } catch (error) {
        console.log(error);
      }
    },

    detectSpotify() {
      const baseUrl = window.location.host.toString();
      //if(baseUrl.includes('spotify')|| baseUrl.includes('warrence')){
      if (baseUrl.includes("spotify")) {
        this.isSpotifySite = true;
      }
    },

    isMenuActive(url) {
      return this.$route.path.indexOf(url) === 0;
    },

    isMenuOpen(url) {
      return (
        this.$route.path.indexOf(url) === 0 && this.orientation !== "horizontal"
      );
    },

    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    },
  },
};
</script>