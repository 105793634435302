<script>
    import BandForm from '@/components/bands/BandForm'
    export default {
        extends : BandForm,
        name: "SharedBandForm",
        methods: {
            showSharedModal(options = {}) {

                console.log('shared modal called');
                console.log(options);
                
                
                if(options.sourceId){
                    this.sharedModalOptions.sourceId = options.sourceId;
                }

                if(options.hideContactForm){
                    this.sharedModalOptions.hideContactForm = options.hideContactForm;
                }

                this.loadContacts();
                this.loadCountries();
                this.loadSocialMedias();
                this.loadMusicMedias();

                this.showModal = true;
            }
        }
    }
</script>