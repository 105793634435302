var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-wrapper layout-1" },
    [
      _c("notifications", {
        attrs: {
          group: "notifications-default",
          duration: 8000,
          speed: 1000,
          position: "top right"
        }
      }),
      _vm._v(" "),
      _c("vue-snotify"),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "trackingReminder",
          staticStyle: { "z-index": "9999991" },
          attrs: {
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
            "hide-header-close": "",
            "hide-footer": "",
            title: "Time Tracking Reminder"
          }
        },
        [
          _c(
            "div",
            { staticClass: "d-block text-center" },
            [
              _c("p", [
                _vm._v(
                  "\n        We are still tracking your time for Task:\n        "
                ),
                _vm.selectedTracker
                  ? _c("span", [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.selectedTracker.task.name))
                      ]),
                      _vm._v("\n          on Project:\n          "),
                      _c("strong", [
                        _vm._v(_vm._s(_vm.selectedTracker.project.name))
                      ]),
                      _vm._v(".\n        ")
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        If you forgot to stop your time, select\n        "
                ),
                _c("strong", [_vm._v("Adjust End Time")]),
                _vm._v(" below.\n      ")
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("\n        Otherwise, select\n        "),
                _c("strong", [_vm._v("Ignore")]),
                _vm._v(" for your time tracking to continue.\n      ")
              ]),
              _vm._v(" "),
              _c(
                "b-button-group",
                [
                  _c("b-button", { on: { click: _vm.adjustTimeTracking } }, [
                    _c("i", { staticClass: "fa fa-pencil-alt" }),
                    _vm._v("   Adjust End Time\n        ")
                  ]),
                  _vm._v(" "),
                  _c("b-button", { on: { click: _vm.ignoreTimeTracking } }, [
                    _c("i", { staticClass: "far fa-times-circle" }),
                    _vm._v("   Ignore\n        ")
                  ])
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("time-tracking-form", {
        ref: "timeTrackingForm",
        attrs: { "edit-id": _vm.adjustTrackerId, type: "track" },
        on: {
          "on-hide": _vm.trackerAdjustHidden,
          "on-save": _vm.trackerAdjusted
        }
      }),
      _vm._v(" "),
      _c("notifications", {
        attrs: { group: "task-assignment", position: "top right" },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function(props) {
              return [
                _c("div", { staticClass: "reminder-wrap" }, [
                  _c("div", { staticClass: "reminder-content" }, [
                    _c("div", { staticClass: "reminder-description" }, [
                      _vm._v(
                        "\n            You have been assigned to a new To Do\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "reminder-title" }, [
                      _vm._v(_vm._s(props.item.title))
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "reminder-description",
                      domProps: { innerHTML: _vm._s(props.item.text) }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "reminder-action" },
                    [
                      _c(
                        "b-button-group",
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-pdless",
                              on: {
                                click: function($event) {
                                  return _vm.handleOpenTask(
                                    props.item.data.task_id,
                                    props.item.data.id,
                                    props
                                  )
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fa fa-search" }),
                              _vm._v("   Open\n            ")
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-pdless",
                              on: {
                                click: function($event) {
                                  return _vm.markNotificationAsRead(
                                    props,
                                    props.item.data.id
                                  )
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "far fa-times-circle" }),
                              _vm._v("   Close\n            ")
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-pdless",
                              on: { click: _vm.clearAllNotification }
                            },
                            [
                              _c("i", { staticClass: "fa fa-ban" }),
                              _vm._v("   Clear All\n            ")
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("notifications", {
        staticStyle: { width: "310px" },
        attrs: { group: "booking-assignment", position: "top right" },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function(props) {
              return [
                _c("div", { staticClass: "reminder-wrap" }, [
                  _c("div", { staticClass: "reminder-content" }, [
                    _c("div", { staticClass: "reminder-description" }, [
                      _c("div", { staticClass: "text-right" }, [
                        _c("i", {
                          staticClass: "far fa-times",
                          on: {
                            click: function($event) {
                              return _vm.markNotificationAsRead(
                                props,
                                props.item.data.id
                              )
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      props.item.data.is_recurring
                        ? _c("span", [
                            _vm._v(
                              " You have been assigned to a new recurring booking:"
                            )
                          ])
                        : _c("span", [
                            _vm._v(" You have been assigned to a new booking:")
                          ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "reminder-title" }, [
                      _vm._v(_vm._s(props.item.title))
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "reminder-description",
                      domProps: { innerHTML: _vm._s(props.item.text) }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "reminder-action" },
                    [
                      _c(
                        "b-button-group",
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-pdless",
                              on: {
                                click: function($event) {
                                  _vm.openBooking(props.item.data.booking_id)
                                  _vm.markNotificationAsRead(
                                    props,
                                    props.item.data.id
                                  )
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fa fa-search" }),
                              _vm._v("   Open\n            ")
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-pdless",
                              on: {
                                click: function($event) {
                                  return _vm.markNotificationAsRead(
                                    props,
                                    props.item.data.id
                                  )
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "far fa-times-circle" }),
                              _vm._v("   Close\n            ")
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-pdless",
                              on: { click: _vm.clearAllNotification }
                            },
                            [
                              _c("i", { staticClass: "fa fa-ban" }),
                              _vm._v("   Clear All\n            ")
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("notifications", {
        attrs: { group: "comments", position: "top right" },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function(props) {
              return [
                _c("div", { staticClass: "reminder-wrap" }, [
                  _c("div", { staticClass: "reminder-content" }, [
                    _c("div", { staticClass: "reminder-title" }, [
                      _vm._v(_vm._s(props.item.title))
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "reminder-description",
                      domProps: { innerHTML: _vm._s(props.item.text) }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "reminder-action" },
                    [
                      _c(
                        "b-button-group",
                        [
                          _c(
                            "b-button",
                            {
                              on: {
                                click: function($event) {
                                  props.close(),
                                    _vm.goToComment(props.item.data)
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fa fa-search" }),
                              _vm._v("   Open\n            ")
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              on: {
                                click: function($event) {
                                  return props.close()
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "far fa-times-circle" }),
                              _vm._v("   Close\n            ")
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("notifications", {
        attrs: { group: "reminder", position: "top right" },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function(props) {
              return [
                _c("div", { staticClass: "reminder-wrap" }, [
                  _c("div", { staticClass: "reminder-content" }, [
                    _c("div", { staticClass: "reminder-title" }, [
                      _vm._v(_vm._s(props.item.title))
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "reminder-description",
                      domProps: { innerHTML: _vm._s(props.item.text) }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "reminder-action" },
                    [
                      _c(
                        "b-button-group",
                        [
                          _c(
                            "b-dropdown",
                            { attrs: { left: "", text: "Snooze" } },
                            [
                              _c("template", { slot: "button-content" }, [
                                _c("i", { staticClass: "far fa-bell-slash" }),
                                _vm._v(" Snooze\n              ")
                              ]),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.$emit("snooze", {
                                        duration: "5 Minutes",
                                        notification: props.item.data
                                      }),
                                        props.close()
                                    }
                                  }
                                },
                                [_vm._v("5 Minutes")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.$emit("snooze", {
                                        duration: "10 Minutes",
                                        notification: props.item.data
                                      }),
                                        props.close()
                                    }
                                  }
                                },
                                [_vm._v("10 Minutes")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.$emit("snooze", {
                                        duration: "15 Minutes",
                                        notification: props.item.data
                                      }),
                                        props.close()
                                    }
                                  }
                                },
                                [_vm._v("15 Minutes")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.$emit("snooze", {
                                        duration: "30 Minutes",
                                        notification: props.item.data
                                      }),
                                        props.close()
                                    }
                                  }
                                },
                                [_vm._v("30 Minutes")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.$emit("snooze", {
                                        duration: "1 Hour",
                                        notification: props.item.data
                                      }),
                                        props.close()
                                    }
                                  }
                                },
                                [_vm._v("1 Hour")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.$emit("snooze", {
                                        duration: "2 Hours",
                                        notification: props.item.data
                                      }),
                                        props.close()
                                    }
                                  }
                                },
                                [_vm._v("2 Hours")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.$emit("snooze", {
                                        duration: "1 Day",
                                        notification: props.item.data
                                      }),
                                        props.close()
                                    }
                                  }
                                },
                                [_vm._v("1 Day")]
                              )
                            ],
                            2
                          ),
                          _vm._v(" "),
                          props.item.data.reminderable_type == "tasks"
                            ? _c(
                                "b-button",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.handleOpen(
                                        props.item.data.reminderable_id
                                      ),
                                        props.close()
                                    }
                                  }
                                },
                                [
                                  _c("i", { staticClass: "fa fa-pencil-alt" }),
                                  _vm._v("   Open\n            ")
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("b-button", { on: { click: props.close } }, [
                            _c("i", { staticClass: "far fa-times-circle" }),
                            _vm._v("   Close\n            ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("notifications", {
        staticStyle: { top: "38px !important" },
        attrs: {
          group: "tracker-minimized",
          position: "top center",
          width: "340px"
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function(props) {
              return [
                _c("div", { staticClass: "reminder-wrap" }, [
                  _c("div", { staticClass: "reminder-content" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-5" }, [
                        _c("div", { staticClass: "display-4" }, [
                          _vm._v(_vm._s(_vm.tracker))
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-7 d-inline" },
                        [
                          _c(
                            "b-button-group",
                            {
                              staticClass: "float-right mt-1",
                              attrs: { size: "sm" }
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticStyle: {
                                    background: "transparent",
                                    "border-right":
                                      "1px solid rgba(0, 0, 0, 0.1)"
                                  },
                                  on: {
                                    click: function($event) {
                                      props.close(), _vm.stopTracker()
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "far fa-stop-circle"
                                  }),
                                  _vm._v("   Stop\n                ")
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  staticStyle: {
                                    background: "transparent",
                                    "border-left": "none"
                                  },
                                  on: {
                                    click: function($event) {
                                      props.close(), _vm.toggleTimeTracker()
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "far fa-window-maximize"
                                  }),
                                  _vm._v("   Maximize\n                ")
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("notifications", {
        attrs: {
          group: "booking-service-order-approval",
          position: "top right"
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function(props) {
              return [
                _c("div", { staticClass: "reminder-wrap" }, [
                  _c("div", { staticClass: "reminder-content" }, [
                    _c("div", { staticClass: "reminder-description" }, [
                      _vm._v(
                        "\n            You have a new booking request:\n            "
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "float-right",
                          on: {
                            click: function($event) {
                              return props.close()
                            }
                          }
                        },
                        [_c("i", { staticClass: "fas fa-times" })]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "reminder-title" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(props.item.title) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "reminder-description",
                      domProps: { innerHTML: _vm._s(props.item.text) }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "reminder-action" },
                    [
                      _c(
                        "b-button-group",
                        [
                          _c(
                            "b-button",
                            {
                              on: {
                                click: function($event) {
                                  _vm.acceptBookingServiceOrder(
                                    props.item.data.booking_service_order_id,
                                    props.item.data.id
                                  ),
                                    _vm.markNotificationAsRead(
                                      props,
                                      props.item.data.id
                                    )
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fa fa-check" }),
                              _vm._v("   Accept\n            ")
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              on: {
                                click: function($event) {
                                  _vm.rescheduleBookingServiceOrder(
                                    props.item.data
                                  ),
                                    _vm.markNotificationAsRead(
                                      props,
                                      props.item.data.id
                                    )
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fa fa-calendar-day" }),
                              _vm._v("   Reschedule\n            ")
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              on: {
                                click: function($event) {
                                  _vm.rejectBookingServiceOrder(
                                    props.item.data.booking_service_order_id,
                                    props.item.data.id
                                  ),
                                    _vm.markNotificationAsRead(
                                      props,
                                      props.item.data.id
                                    )
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fas fa-times" }),
                              _vm._v("   Reject\n            ")
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-center mt-1 small" }, [
                        _vm._v(
                          "\n            Rejecting a booking will cancel any deposit payment for this\n            booking request.\n          "
                        )
                      ])
                    ],
                    1
                  )
                ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("notifications", {
        attrs: { group: "tracker", position: "top center" },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function(props) {
              return [
                _c("div", { staticClass: "reminder-wrap" }, [
                  _c("div", { staticClass: "reminder-content" }, [
                    _c("div", { staticClass: "reminder-title" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(props.item.data.project.name) +
                          " : " +
                          _vm._s(props.item.title) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "reminder-description" }, [
                      _c("div", { staticClass: "mt-2" }, [
                        _c("small", { staticClass: "text-bold" }, [
                          _vm._v("Elapsed Time:")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "display-4" }, [
                          _vm._v(_vm._s(_vm.tracker))
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mt-1" },
                        [
                          _c("strong", [_vm._v("Current Task:")]),
                          _vm._v(" "),
                          _c("b-form-select", {
                            attrs: { options: _vm.trackerTasks },
                            model: {
                              value: _vm.selectedTrackerTask,
                              callback: function($$v) {
                                _vm.selectedTrackerTask = $$v
                              },
                              expression: "selectedTrackerTask"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "reminder-action" },
                    [
                      _c(
                        "b-button-group",
                        [
                          _c(
                            "b-button",
                            {
                              on: {
                                click: function($event) {
                                  props.close(), _vm.stopTracker()
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "far fa-stop-circle" }),
                              _vm._v("   Stop\n            ")
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              on: {
                                click: function($event) {
                                  props.close(), _vm.toggleTimeTracker()
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "far fa-window-minimize"
                              }),
                              _vm._v("   Minimize\n            ")
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("notifications", {
        attrs: { group: "comments", position: "top right" },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function(props) {
              return [
                _c("div", { staticClass: "reminder-wrap" }, [
                  _c("div", { staticClass: "reminder-content" }, [
                    _c("div", { staticClass: "reminder-title" }, [
                      _vm._v(_vm._s(props.item.title))
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "reminder-description",
                      domProps: { innerHTML: _vm._s(props.item.text) }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "reminder-action" },
                    [
                      _c(
                        "b-button-group",
                        [
                          _c(
                            "b-button",
                            {
                              on: {
                                click: function($event) {
                                  props.close(),
                                    _vm.goToComment(props.item.data)
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fa fa-search" }),
                              _vm._v("   Open\n            ")
                            ]
                          ),
                          _vm._v(" "),
                          _c("b-button", { on: { click: props.close } }, [
                            _c("i", { staticClass: "far fa-times-circle" }),
                            _vm._v("   Close\n            ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("notifications", {
        attrs: { group: "reminder", position: "top right" },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function(props) {
              return [
                _c("div", { staticClass: "reminder-wrap" }, [
                  _c("div", { staticClass: "reminder-content" }, [
                    _c("div", { staticClass: "reminder-title" }, [
                      _vm._v(_vm._s(props.item.title))
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "reminder-description",
                      domProps: { innerHTML: _vm._s(props.item.text) }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "reminder-action" },
                    [
                      _c(
                        "b-button-group",
                        [
                          _c(
                            "b-dropdown",
                            { attrs: { left: "", text: "Snooze" } },
                            [
                              _c("template", { slot: "button-content" }, [
                                _c("i", { staticClass: "far fa-bell-slash" }),
                                _vm._v(" Snooze\n              ")
                              ]),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.$emit("snooze", {
                                        duration: "5 Minutes",
                                        notification: props.item.data
                                      }),
                                        props.close()
                                    }
                                  }
                                },
                                [_vm._v("5 Minutes")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.$emit("snooze", {
                                        duration: "10 Minutes",
                                        notification: props.item.data
                                      }),
                                        props.close()
                                    }
                                  }
                                },
                                [_vm._v("10 Minutes")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.$emit("snooze", {
                                        duration: "15 Minutes",
                                        notification: props.item.data
                                      }),
                                        props.close()
                                    }
                                  }
                                },
                                [_vm._v("15 Minutes")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.$emit("snooze", {
                                        duration: "30 Minutes",
                                        notification: props.item.data
                                      }),
                                        props.close()
                                    }
                                  }
                                },
                                [_vm._v("30 Minutes")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.$emit("snooze", {
                                        duration: "1 Hour",
                                        notification: props.item.data
                                      }),
                                        props.close()
                                    }
                                  }
                                },
                                [_vm._v("1 Hour")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.$emit("snooze", {
                                        duration: "2 Hours",
                                        notification: props.item.data
                                      }),
                                        props.close()
                                    }
                                  }
                                },
                                [_vm._v("2 Hours")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.$emit("snooze", {
                                        duration: "1 Day",
                                        notification: props.item.data
                                      }),
                                        props.close()
                                    }
                                  }
                                },
                                [_vm._v("1 Day")]
                              )
                            ],
                            2
                          ),
                          _vm._v(" "),
                          props.item.data.reminderable_type == "tasks"
                            ? _c(
                                "b-button",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.handleOpen(
                                        props.item.data.reminderable_id
                                      ),
                                        props.close()
                                    }
                                  }
                                },
                                [
                                  _c("i", { staticClass: "fa fa-pencil-alt" }),
                                  _vm._v("   Open\n            ")
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("b-button", { on: { click: props.close } }, [
                            _c("i", { staticClass: "far fa-times-circle" }),
                            _vm._v("   Close\n            ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("task-edit-modal", {
        ref: "taskEditModal",
        attrs: { id: _vm.selectedTaskId }
      }),
      _vm._v(" "),
      _c("task-edit-modal", {
        ref: "taskEditModal",
        attrs: { id: _vm.selectedTaskId }
      }),
      _vm._v(" "),
      _c("booking-form", {
        ref: "bookingFormModal",
        attrs: {
          "robin-booking-data": _vm.selectedRobinBooking,
          "booking-data": _vm.selectedBooking,
          "booking-id": _vm.selectedBookingId,
          "booking-order-id": _vm.selectedBookingOrderId,
          "notification-id": _vm.selectedNotificationId
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "layout-inner" },
        [
          _c("app-layout-navbar", { ref: "layout-navbar" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "layout-container" },
            [
              _c("app-layout-sidenav"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "layout-content" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "router-transitions container-fluid flex-grow-1 container-p-y"
                    },
                    [_c("router-view")],
                    1
                  ),
                  _vm._v(" "),
                  _c("app-layout-footer")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "layout-overlay",
        on: { click: _vm.closeSidenav }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }