<template>
  <div>
    <b-modal
      :no-close-on-backdrop="true"
      id="project-song-form"
      size="lg"
      no-enforce-focus
      :title="formTitle"
      v-model="showModal"
      @show="onShow"
      @hidden="onHidden"
      ref="modal"
    >
      <b-form @submit.prevent="handleSubmit">
        <h5>Song Info</h5>
        <b-form-row>
          <b-form-group label="Song Name" class="col-12">
            <b-input type="text" v-model="song.name"></b-input>
            <div
              v-if="!$v.song.name.required && $v.song.name.$dirty"
              class="invalid-feedback"
              style="display: inline-block"
            >
              Song name field is required.
            </div>
          </b-form-group>
        </b-form-row>

        <b-form-row>
          <b-form-group label="Key Signature" class="col-12">
            <b-input type="text" v-model="song.key_signature"></b-input>
          </b-form-group>
        </b-form-row>

        <b-form-row>
          <b-form-group label="Meter" class="col-6">
            <b-input type="text" v-model="song.meter"></b-input>
          </b-form-group>

          <b-form-group label="Tempo" class="col-6">
            <b-input type="text" v-model="song.tempo"></b-input>
          </b-form-group>
        </b-form-row>

        <b-form-row>
          <b-form-group label="ISRC" class="col-6">
            <b-input type="text" v-model="metadata.isrc"></b-input>
          </b-form-group>
        </b-form-row>

        <div class="b-form-row">
          <b-form-group label="Notes">
            <b-textarea v-model="song.notes" rows="5" />
          </b-form-group>
        </div>

        <h5>Participant Info</h5>

        <div
          :key="index"
          v-for="(v, index) in $v.song.participants.$each.$iter"
          class="border-dashed mb-4 bg-lighter"
        >
          <i
            v-b-tooltip.hover
            title="Delete Participant"
            v-if="song.participants.length > 1"
            @click="deleteParticipant(index)"
            class="fa fa-times text-danger ml-2 mt-2"
          ></i>

          <div class="pt-2 pl-3 pr-3 pb-2">
            <b-form-row>
              <b-form-group class="col">
                <slot name="label">
                  <span class="col-form-label pt-0">Participant Name</span>
                </slot>

                <multiselect
                  :class="{
                    'mt-1': 1,
                    'is-invalid': !v.name.required && v.name.$dirty,
                  }"
                  v-model="v.name.$model"
                  :id="'participant-' + index"
                  :custom-label="participantLabel"
                  track-by="id"
                  placeholder="Type to search"
                  open-direction="bottom"
                  :options="primaryContactOptions"
                  :multiple="false"
                  :searchable="true"
                  :loading="isPrimaryContactLoading"
                  :internal-search="true"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :options-limit="300"
                  :limit="99"
                  :max-height="600"
                  @select="onSelect"
                >
                  <template slot="tag" slot-scope="{ option, remove }">
                    <span class="badge badge-primary mr-2">
                      <span>{{ option.full_name }}</span>
                      <a
                        style="color: white"
                        href="javascript:void(0)"
                        @click="remove(option)"
                      >
                        <i class="fa fa-times ml-2"></i>
                      </a>
                    </span>
                  </template>
                  <span slot="noResult">Contact not found.</span>
                </multiselect>

                <div
                  class="invalid-feedback"
                  style="display: inline-block"
                  v-if="!v.name.required && v.name.$dirty"
                >
                  Name field is required.
                </div>
              </b-form-group>

              <b-form-group label="Participant Type" class="col">
                <b-form-select
                  v-model="v.type_id.$model"
                  :options="participantTypeOptions"
                  :class="{
                    'is-invalid': !v.type_id.required && v.type_id.$dirty,
                  }"
                ></b-form-select>

                <div
                  class="invalid-feedback"
                  style="display: inline-block"
                  v-if="!v.type_id.required && v.type_id.$dirty"
                >
                  Type field is required.
                </div>
              </b-form-group>
            </b-form-row>
            <b-form-row>
              <b-form-group label="Phone" class="col">
                <b-input type="text" v-model="v.$model.phone"></b-input>
              </b-form-group>

              <b-form-group label="Email" class="col">
                <b-input type="text" v-model="v.email.$model"></b-input>
                <div
                  class="invalid-feedback"
                  style="display: inline-block"
                  v-if="!v.email.required && v.email.$dirty"
                >
                  Email field is required.
                </div>
              </b-form-group>
            </b-form-row>
          </div>
        </div>

        <a @click="addParticipant" class="btn btn-primary btn-sm text-white">
          <i class="ion ion-md-add-circle"></i> Add Participant
        </a>
        <a @click="addContact" class="ml-2 btn btn-primary btn-sm text-white">
          <i class="ion ion-md-add-circle"></i> Add Contact
        </a>
      </b-form>

      <template slot="modal-footer">
        <b-button :disabled="loading" variant="default" @click="cancel()"
          >Cancel</b-button
        >
        <b-button :disabled="loading" variant="primary" @click="handleSubmit()"
          >Save</b-button
        >
      </template>
    </b-modal>

    <contact-form ref="contactFormModal" @on-save="newContactAdded" />
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>


<script>
import Vue from "vue";
import Vuelidate from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
Vue.use(Vuelidate);

import Multiselect from "vue-multiselect";

import ContactForm from "@/components/contacts/ContactForm";

const initSong = {
  name: "",
  key_signature: "",
  meter: "",
  tempo: "",
  isrc: "",
  notes: "",
  metadata: [
    {
      name: "isrc",
      value: "",
    },
  ],
  participants: [
    {
      name: "",
      type_id: "",
      phone: "",
      email: "",
    },
  ],
};

export default {
  name: "ProjectSongForm",
  props: ["editId", "projectId"],
  components: {
    ContactForm,
    Multiselect,
  },
  data: () => ({
    showModal: false,
    loading: false,
    song: { ...initSong },
    primaryContactOptions: [],
    isPrimaryContactLoading: false,
    participantTypeOptions: [],
    metadata: {
      isrc: "",
    },
  }),
  computed: {
    formTitle() {
      if (this.editId) {
        return "Edit Project Song";
      }
      return "Add Project Song";
    },
  },
  methods: {
    async loadContactOptions() {
      try {
        this.isPrimaryContactLoading = true;
        const queryParams = {
          'fields' : 'id,first_name,last_name,email,phone_number'
        }
        var queryString = Object.keys(queryParams).map(function(key) {
            return key + '=' + queryParams[key]
        }).join('&');

        const contactResponse = await window.axios.get(
          `/api/v2/contacts/options?${queryString}`
        );

        const contactOptions = contactResponse.data.map((contact) => {
          contact.full_name = contact.first_name +' '+ contact.last_name;
          return contact;
        });

        this.primaryContactOptions = contactOptions;
        this.isPrimaryContactLoading = false;

      } catch (error) {
        console.log(error);
      }
    },

    async loadParticipantTypes() {
      const participantTypeResponse = await window.axios.get(
        "/api/picklists/Project Song Participant Types"
      );

      const participantTypes = participantTypeResponse.data.data.options.map(
        (genre) => {
          return {
            value: genre.id,
            text: genre.name,
          };
        }
      );
      this.participantTypeOptions = [
        ...[{ value: "", text: "Choose Type" }],
        ...participantTypes,
      ];
    },
    newContactAdded(contact) {
      this.song.participants.push({
        name: contact,
        email: contact.email,
        type_id: "",
        phone: contact.phone_number,
      });
    },
    addContact() {
      this.$refs.contactFormModal.show();
    },
    participantLabel(data) {
      return `${data.first_name} ${data.last_name} (${data.email})`;
    },
    onHidden() {
      this.$v.$reset();
      this.song = {
        ...initSong,
        participants: [
          {
            name: "",
            type_id: "",
            phone: "",
            email: "",
          },
        ],
        metadata: [
          {
            name: "isrc",
            value: "",
          },
        ],
      };
    },
    async onShow() {
      this.loadContactOptions();
      this.loadParticipantTypes();
      this.$v.$reset();
      if (this.editId) {
        this.loadSongDetail();
      } else {
        this.song = {
          ...initSong,
          participants: [
            {
              name: "",
              type_id: "",
              phone: "",
              email: "",
            },
          ],
          metadata: [
            {
              name: "isrc",
              value: "",
            },
          ],
        };
      }
    },
    async loadSongDetail() {
      const detailResponse = await window.axios.get(
        `/api/projects/${this.projectId}/songs/${this.editId}`
      );

      let songData = detailResponse.data.data;
      songData.participants.forEach((participant) => {
        participant.name = participant.contact;
      });

      songData.metadata.forEach((metadataItem) => {
        if (metadataItem.name == "isrc")
          this.metadata.isrc = metadataItem.value;
      });

      this.song = songData;
    },
    displayModal() {
      this.$v.$reset();
      this.song = {
        ...initSong,
        participants: [
          {
            name: "",
            type_id: "",
            phone: "",
            email: "",
          },
        ],
      };
      this.showModal = true;
    },
    hideModal() {
      this.$v.$reset();
      this.song = {
        ...initSong,
        participants: [
          {
            name: "",
            type_id: "",
            phone: "",
            email: "",
          },
        ],
      };
      this.showModal = false;
    },
    cancel() {
      this.hideModal();
    },
    addParticipant() {
      this.song.participants.push({
        name: "",
        type_id: "",
        phone: "",
        email: "",
      });
    },
    deleteParticipant(index) {
      this.song.participants.splice(index, 1);
    },
    onSelect(data, index) {
      const participantIndex = parseInt(index.replace("participant-", ""));
      this.$set(this.song.participants[participantIndex], "email", data.email);
      this.$set(
        this.song.participants[participantIndex],
        "phone",
        data.phone_number
      );
    },
    async handleSubmit() {
      this.$v.$touch();

      const isValid = !this.$v.$invalid;
      if (isValid) {
        try {
          const postedData = { ...this.song };

          postedData.participants.forEach((participant) => {
            participant.contact_id = participant.name.id;
            delete participant.name;
            delete participant.contact;
          });

          delete postedData.metadata;

          postedData.metadata = [];

          for (const name in this.metadata) {
            let item = {};
            item.id = this.editId ? this.editId : null;
            item.name = name;
            item.value = this.metadata[name];
            if (item.value == null) item.value = "";
            postedData.metadata.push(item);
          }

          this.metadata.isrc = "";

          if (this.editId) {
            const reqResponse = await window.axios.put(
              `/api/projects/${this.projectId}/songs/${this.editId}`,
              postedData
            );
            this.$snotify.success(`Update Song Success`, "Success!");
            this.$emit("on-save", reqResponse);
          } else {
            const reqResponse = await window.axios.post(
              `/api/projects/${this.projectId}/songs`,
              postedData
            );
            this.$snotify.success(`Add Song Success`, "Success!");
            this.$emit("on-save", reqResponse);
          }

          this.hideModal();
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  validations: {
    song: {
      name: {
        required,
      },
      participants: {
        required,
        minLength: minLength(1),
        $each: {
          name: {
            required,
          },
          type_id: {
            required,
          },
          email: {
            required,
          },
        },
      },
    },
  },
};
</script>
<style scoped>
.border-dashed {
  border: 1px dashed #f5f5f5;
  border-radius: 7px;
}
.bg-lighter {
  background: #f5f5f5;
}
</style>