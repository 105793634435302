var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    {
      staticClass: "layout-navbar align-items-lg-center container-p-x",
      attrs: { toggleable: "lg", variant: _vm.getLayoutNavbarBg() }
    },
    [
      _c("b-navbar-brand", { attrs: { to: _vm.redirectPermissions } }, [
        _c("img", { attrs: { src: _vm.logo, height: "40" } })
      ]),
      _vm._v(" "),
      _vm.sidenavToggle
        ? _c(
            "b-navbar-nav",
            { staticClass: "align-items-lg-center mr-auto mr-lg-4" },
            [
              _c(
                "a",
                {
                  staticClass: "nav-item nav-link px-0 ml-2 ml-lg-0",
                  attrs: { href: "javascript:void(0)" },
                  on: { click: _vm.toggleSidenav }
                },
                [
                  _c("i", {
                    staticClass: "ion ion-md-menu text-large align-middle"
                  })
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("b-navbar-toggle", { attrs: { target: "app-layout-navbar" } }),
      _vm._v(" "),
      _c(
        "b-collapse",
        { attrs: { "is-nav": "", id: "app-layout-navbar" } },
        [
          _c("hr", { staticClass: "d-lg-none w-100 my-2" }),
          _vm._v(" "),
          _c(
            "b-navbar-nav",
            { staticClass: "align-items-lg-center ml-auto" },
            [
              _c(
                "b-nav-item-dropdown",
                {
                  staticClass: "demo-navbar-notifications mr-lg-3",
                  attrs: { "no-caret": "", right: !_vm.isRTL }
                },
                [
                  _c(
                    "template",
                    { staticClass: "item", slot: "button-content" },
                    [
                      _c("i", {
                        staticClass:
                          "ion ion-md-notifications-outline navbar-icon align-middle"
                      }),
                      _vm._v(" "),
                      _vm.unreadNotification > 0
                        ? _c("span", { staticClass: "notify-badge" }, [
                            _vm._v(_vm._s(_vm.unreadNotification))
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("span", { staticClass: "d-lg-none align-middle" }, [
                        _vm._v("  Notifications")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "bg-primary text-center text-white font-weight-bold p-3"
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.unreadNotification) +
                          " New Notifications\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-list-group",
                    { attrs: { flush: "" } },
                    _vm._l(_vm.notifications, function(notification) {
                      return _c(
                        "b-list-group-item",
                        {
                          key: notification.id,
                          staticClass: "media d-flex align-items-center bg-",
                          class: { "bg-lighter": notification.read_at == null },
                          attrs: { href: "javascript:void(0)" }
                        },
                        [
                          _c("div", {
                            staticClass:
                              "ui-icon ui-icon-sm ion ion ion-md-megaphone bg-success border-0 text-white"
                          }),
                          _vm._v(" "),
                          notification.type ==
                          "App\\Notifications\\ReviewCommentNotification"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "media-body line-height-condenced ml-3"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.showMessage(notification)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-dark font-weight-bold"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(notification.data.type) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "text-dark" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              notification.data.description
                                            ) +
                                            "\n                "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "text-light small" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.formatTimeZoneFromNow(
                                                  notification.created_at
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            : notification.type ==
                              "App\\Notifications\\ClientUploadNewFileNotification"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "media-body line-height-condenced ml-3"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.showMessage(notification)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-dark font-weight-bold"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  New client files have been uploaded.\n                "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "text-dark" }, [
                                        _vm._v(
                                          "\n                  New client files have been uploaded to\n                  " +
                                            _vm._s(
                                              notification.data.project_name
                                            ) +
                                            ".\n                "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "text-light small" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.formatTimeZoneFromNow(
                                                  notification.created_at
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            : notification.type ==
                              "App\\Notifications\\BookingServicePendingOrderNotification"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "media-body line-height-condenced ml-3"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.showMessage(notification)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-dark font-weight-bold"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Booking Request - " +
                                              _vm._s(notification.data.title) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "text-light small" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.formatTimeZoneFromNow(
                                                  notification.created_at
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "media-body line-height-condenced ml-3"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.showMessage(notification)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-dark font-weight-bold"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(notification.data.title) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "text-light small" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.formatTimeZoneFromNow(
                                                  notification.created_at
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                        ]
                      )
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "d-block text-center text-light small p-2 my-1",
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.markAllRead()
                        }
                      }
                    },
                    [_vm._v("Show all notifications")]
                  )
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1"
                },
                [_vm._v("\n        |\n      ")]
              ),
              _vm._v(" "),
              _c(
                "b-nav-item-dropdown",
                {
                  staticClass: "demo-navbar-user",
                  attrs: { right: !_vm.isRTL }
                },
                [
                  _c("template", { slot: "button-content" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "d-inline-flex flex-lg-row-reverse align-items-center align-middle"
                      },
                      [
                        _vm.photo_path == null
                          ? _c("span", [
                              _c("i", {
                                staticClass: "fa fa-user-circle fa-2x"
                              })
                            ])
                          : _c("img", {
                              staticClass: "d-block ui-w-30 rounded-circle",
                              attrs: { src: _vm.photo_path, alt: "" }
                            }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "px-1 mr-lg-2 ml-2 ml-lg-0" },
                          [_vm._v(_vm._s(_vm.first_name + " " + _vm.last_name))]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-dd-item",
                    [
                      _c("router-link", { attrs: { to: "/profile" } }, [
                        _c("i", {
                          staticClass: "ion ion-ios-person text-lightest"
                        }),
                        _vm._v("  "),
                        _c("span", { staticClass: "text-primary" }, [
                          _vm._v("My Profile")
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("b-dd-divider"),
                  _vm._v(" "),
                  _c(
                    "b-dd-item",
                    {
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.logout.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "ion ion-ios-log-out text-danger"
                      }),
                      _vm._v("   Log Out\n        ")
                    ]
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "notification-modal",
          attrs: {
            centered: "",
            id: "notification-modal",
            "header-text-variant": "dark",
            "body-text-variant": "dark",
            title: _vm.notif.title,
            "hide-footer": ""
          }
        },
        [
          _c("div", {
            staticClass: "text-dark",
            domProps: { innerHTML: _vm._s(_vm.notif.description) }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }