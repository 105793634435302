import { render, staticRenderFns } from "./App.vue?vue&type=template&id=2db15582&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "@/vendor/styles/bootstrap.scss?vue&type=style&index=0&lang=scss&"
import style1 from "@/vendor/styles/appwork.scss?vue&type=style&index=1&lang=scss&"
import style2 from "@/vendor/styles/theme-corporate.scss?vue&type=style&index=2&lang=scss&"
import style3 from "@/vendor/styles/colors.scss?vue&type=style&index=3&lang=scss&"
import style4 from "@/vendor/styles/uikit.scss?vue&type=style&index=4&lang=scss&"
import style5 from "@/vendor/libs/spinkit/spinkit.scss?vue&type=style&index=5&lang=scss&"
import style6 from "@/vendor/libs/vue-notification/vue-notification.scss?vue&type=style&index=6&lang=scss&"
import style7 from "./style.scss?vue&type=style&index=7&lang=scss&"
import style8 from "./demo.css?vue&type=style&index=8&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/code/nathan/Sonido_SaaS/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2db15582')) {
      api.createRecord('2db15582', component.options)
    } else {
      api.reload('2db15582', component.options)
    }
    module.hot.accept("./App.vue?vue&type=template&id=2db15582&", function () {
      api.rerender('2db15582', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/src/App.vue"
export default component.exports