var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            "no-close-on-backdrop": true,
            id: "project-song-form",
            size: "lg",
            "no-enforce-focus": "",
            title: _vm.formTitle
          },
          on: { show: _vm.onShow, hidden: _vm.onHidden },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.handleSubmit.apply(null, arguments)
                }
              }
            },
            [
              _c("h5", [_vm._v("Song Info")]),
              _vm._v(" "),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    { staticClass: "col-12", attrs: { label: "Song Name" } },
                    [
                      _c("b-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.song.name,
                          callback: function($$v) {
                            _vm.$set(_vm.song, "name", $$v)
                          },
                          expression: "song.name"
                        }
                      }),
                      _vm._v(" "),
                      !_vm.$v.song.name.required && _vm.$v.song.name.$dirty
                        ? _c(
                            "div",
                            {
                              staticClass: "invalid-feedback",
                              staticStyle: { display: "inline-block" }
                            },
                            [
                              _vm._v(
                                "\n            Song name field is required.\n          "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "col-12",
                      attrs: { label: "Key Signature" }
                    },
                    [
                      _c("b-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.song.key_signature,
                          callback: function($$v) {
                            _vm.$set(_vm.song, "key_signature", $$v)
                          },
                          expression: "song.key_signature"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    { staticClass: "col-6", attrs: { label: "Meter" } },
                    [
                      _c("b-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.song.meter,
                          callback: function($$v) {
                            _vm.$set(_vm.song, "meter", $$v)
                          },
                          expression: "song.meter"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    { staticClass: "col-6", attrs: { label: "Tempo" } },
                    [
                      _c("b-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.song.tempo,
                          callback: function($$v) {
                            _vm.$set(_vm.song, "tempo", $$v)
                          },
                          expression: "song.tempo"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    { staticClass: "col-6", attrs: { label: "ISRC" } },
                    [
                      _c("b-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.metadata.isrc,
                          callback: function($$v) {
                            _vm.$set(_vm.metadata, "isrc", $$v)
                          },
                          expression: "metadata.isrc"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "b-form-row" },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Notes" } },
                    [
                      _c("b-textarea", {
                        attrs: { rows: "5" },
                        model: {
                          value: _vm.song.notes,
                          callback: function($$v) {
                            _vm.$set(_vm.song, "notes", $$v)
                          },
                          expression: "song.notes"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("h5", [_vm._v("Participant Info")]),
              _vm._v(" "),
              _vm._l(_vm.$v.song.participants.$each.$iter, function(v, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "border-dashed mb-4 bg-lighter" },
                  [
                    _vm.song.participants.length > 1
                      ? _c("i", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          staticClass: "fa fa-times text-danger ml-2 mt-2",
                          attrs: { title: "Delete Participant" },
                          on: {
                            click: function($event) {
                              return _vm.deleteParticipant(index)
                            }
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "pt-2 pl-3 pr-3 pb-2" },
                      [
                        _c(
                          "b-form-row",
                          [
                            _c(
                              "b-form-group",
                              { staticClass: "col" },
                              [
                                _vm._t("label", function() {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "col-form-label pt-0" },
                                      [_vm._v("Participant Name")]
                                    )
                                  ]
                                }),
                                _vm._v(" "),
                                _c(
                                  "multiselect",
                                  {
                                    class: {
                                      "mt-1": 1,
                                      "is-invalid":
                                        !v.name.required && v.name.$dirty
                                    },
                                    attrs: {
                                      id: "participant-" + index,
                                      "custom-label": _vm.participantLabel,
                                      "track-by": "id",
                                      placeholder: "Type to search",
                                      "open-direction": "bottom",
                                      options: _vm.primaryContactOptions,
                                      multiple: false,
                                      searchable: true,
                                      loading: _vm.isPrimaryContactLoading,
                                      "internal-search": true,
                                      "clear-on-select": false,
                                      "close-on-select": true,
                                      "options-limit": 300,
                                      limit: 99,
                                      "max-height": 600
                                    },
                                    on: { select: _vm.onSelect },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "tag",
                                          fn: function(ref) {
                                            var option = ref.option
                                            var remove = ref.remove
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "badge badge-primary mr-2"
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(option.full_name)
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticStyle: {
                                                        color: "white"
                                                      },
                                                      attrs: {
                                                        href:
                                                          "javascript:void(0)"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return remove(option)
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-times ml-2"
                                                      })
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: v.name.$model,
                                      callback: function($$v) {
                                        _vm.$set(v.name, "$model", $$v)
                                      },
                                      expression: "v.name.$model"
                                    }
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "noResult" },
                                        slot: "noResult"
                                      },
                                      [_vm._v("Contact not found.")]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                !v.name.required && v.name.$dirty
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "invalid-feedback",
                                        staticStyle: { display: "inline-block" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                Name field is required.\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              {
                                staticClass: "col",
                                attrs: { label: "Participant Type" }
                              },
                              [
                                _c("b-form-select", {
                                  class: {
                                    "is-invalid":
                                      !v.type_id.required && v.type_id.$dirty
                                  },
                                  attrs: {
                                    options: _vm.participantTypeOptions
                                  },
                                  model: {
                                    value: v.type_id.$model,
                                    callback: function($$v) {
                                      _vm.$set(v.type_id, "$model", $$v)
                                    },
                                    expression: "v.type_id.$model"
                                  }
                                }),
                                _vm._v(" "),
                                !v.type_id.required && v.type_id.$dirty
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "invalid-feedback",
                                        staticStyle: { display: "inline-block" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                Type field is required.\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-row",
                          [
                            _c(
                              "b-form-group",
                              { staticClass: "col", attrs: { label: "Phone" } },
                              [
                                _c("b-input", {
                                  attrs: { type: "text" },
                                  model: {
                                    value: v.$model.phone,
                                    callback: function($$v) {
                                      _vm.$set(v.$model, "phone", $$v)
                                    },
                                    expression: "v.$model.phone"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              { staticClass: "col", attrs: { label: "Email" } },
                              [
                                _c("b-input", {
                                  attrs: { type: "text" },
                                  model: {
                                    value: v.email.$model,
                                    callback: function($$v) {
                                      _vm.$set(v.email, "$model", $$v)
                                    },
                                    expression: "v.email.$model"
                                  }
                                }),
                                _vm._v(" "),
                                !v.email.required && v.email.$dirty
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "invalid-feedback",
                                        staticStyle: { display: "inline-block" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                Email field is required.\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btn-sm text-white",
                  on: { click: _vm.addParticipant }
                },
                [
                  _c("i", { staticClass: "ion ion-md-add-circle" }),
                  _vm._v(" Add Participant\n      ")
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "ml-2 btn btn-primary btn-sm text-white",
                  on: { click: _vm.addContact }
                },
                [
                  _c("i", { staticClass: "ion ion-md-add-circle" }),
                  _vm._v(" Add Contact\n      ")
                ]
              )
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  attrs: { disabled: _vm.loading, variant: "default" },
                  on: {
                    click: function($event) {
                      return _vm.cancel()
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { disabled: _vm.loading, variant: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleSubmit()
                    }
                  }
                },
                [_vm._v("Save")]
              )
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _c("contact-form", {
        ref: "contactFormModal",
        on: { "on-save": _vm.newContactAdded }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }