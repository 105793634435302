<script>
import CompanyForm from "@/components/companies/CompanyForm";
export default {
  extends: CompanyForm,
  name: "SharedCompanyForm",
  methods: {
    showSharedModal(options = {}) {
      if (options.sourceId) {
        this.sharedModalOptions.sourceId = options.sourceId;
      }

      if (options.hideContactForm) {
        this.sharedModalOptions.hideContactForm = options.hideContactForm;
      }      

      let self = this;

      this.loadContacts();

      window.axios.get("/api/countries/get-all-name").then((response) => {
        Object.keys(response.data).forEach((key) => {
          self.countries_options.push({
            text: response.data[key],
            value: response.data[key],
          });
        });
      });

      window.axios.get("/api/social-medias/all?pluck=true").then((response) => {
        self.social_medias = response.data;
      });
      
      this.show_modal = true;
    },
  },
};
</script>
