var render, staticRenderFns
import script from "./SharedPrimaryContactForm.vue?vue&type=script&lang=js&"
export * from "./SharedPrimaryContactForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/code/nathan/Sonido_SaaS/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0395d787')) {
      api.createRecord('0395d787', component.options)
    } else {
      api.reload('0395d787', component.options)
    }
    
  }
}
component.options.__file = "resources/assets/src/components/shared/SharedPrimaryContactForm.vue"
export default component.exports