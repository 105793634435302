<template>
  <div>
    <b-modal
      :no-close-on-backdrop="true"
      id="create-new-project"
      size="lg"
      no-enforce-focus
      :title="formTitle"
      v-model="showModal"
      @shown="focusMyElement"
      @show="onShow"
      @hidden="onHidden"
      ref="modal"
    >
      <b-form @submit.prevent="handleSubmit">
        <b-form-row v-if="projectTypeOptions.length > 1">
          <b-form-group class="col-12" label="Project Type">
            <b-form-radio-group
              v-model="project.type"
              buttons
              button-variant="outline-primary"
              class="project-type-radio mt-md-1"
            >
              <template v-for="option in projectTypeOptions">
                <b-form-radio
                  :value="option.value"
                  :key="option.text"
                  class="project-type-radio-item"
                >
                  <div>
                    <i v-if="option.value == 'music'" class="fas fa-music project-type-radio-icon"></i>
                    <i
                      v-else-if="option.value == 'podcast'"
                      class="fas fa-microphone project-type-radio-icon"
                    ></i>
                    <i
                      v-else-if="option.value == 'audio-book'"
                      class="fas fa-book project-type-radio-icon"
                    ></i>
                    <i
                      v-else-if="option.value == 'post-production'"
                      class="fas fa-sliders-h project-type-radio-icon"
                    ></i>
                    <i
                      v-else-if="option.value == 'voiceover'"
                      class="fas fa-headset project-type-radio-icon"
                    ></i>
                    <i
                      v-else-if="option.value == 'other'"
                      class="fas fa-file-audio project-type-radio-icon"
                    ></i>
                  </div>
                  <div class="project-type-radio-label">
                    {{ option.text }}
                  </div>
                </b-form-radio>
              </template>
            </b-form-radio-group>
          </b-form-group>
        </b-form-row>

        <b-form-row v-if="isSpotify">
          <b-form-group label="Project Code" class="col-12">
            <b-input
              ref="name"
              v-model="project.project_code"
              :class="{
                'is-invalid':
                  projectSubmitted && $v.project.project_code.$error,
              }"
            ></b-input>
            <div
              v-if="projectSubmitted && !$v.project.project_code.required"
              class="invalid-feedback"
            >
              Project Code field is required.
            </div>

            <div
              v-if="projectSubmitted && !$v.project.project_code.maxLength"
              class="invalid-feedback"
            >
              Project Code field cannot more than 6 characters.
            </div>
          </b-form-group>
        </b-form-row>

        <b-form-row>
          <b-form-group label="Project Name" class="col-12">
            <b-input
              ref="name"
              v-model="project.name"
              :class="{
                'is-invalid': projectSubmitted && $v.project.name.$error,
              }"
            ></b-input>
            <div
              v-if="projectSubmitted && !$v.project.name.required"
              class="invalid-feedback"
            >
              Project Name field is required.
            </div>
          </b-form-group>
        </b-form-row>

        <b-form-row>
          <b-form-group label="Target Start Date" class="col-12">
            <div class="input-group">
              <flat-pickr
                v-model="project.start_date"
                :config="datePickerConfig"
                :class="{
                  'is-invalid':
                    projectSubmitted && $v.project.start_date.$error,
                }"
                placeholder="Select Start Date"
                name="start_date"
              >
              </flat-pickr>
              <div class="input-group-btn">
                <button
                  class="btn btn-default"
                  type="button"
                  title="Toggle"
                  data-toggle
                >
                  <i class="fa fa-calendar">
                    <span aria-hidden="true" class="sr-only">Toggle</span>
                  </i>
                </button>
              </div>
              <div
                v-if="projectSubmitted && !$v.project.start_date.required"
                style="display: inline-block"
                class="invalid-feedback"
              >
                Target Start Date field is required.
              </div>
            </div>
          </b-form-group>
        </b-form-row>

        <b-form-row>
          <b-form-group label="Target Completion Date" class="col-12">
            <div class="input-group">
              <flat-pickr
                v-model="project.due_date"
                :config="datePickerConfig"
                :class="{
                  'is-invalid': projectSubmitted && $v.project.due_date.$error,
                }"
                placeholder="Select Target Completion Date"
                name="due_date"
              >
              </flat-pickr>
              <div class="input-group-btn">
                <button
                  class="btn btn-default"
                  type="button"
                  title="Toggle"
                  data-toggle
                >
                  <i class="fa fa-calendar">
                    <span aria-hidden="true" class="sr-only">Toggle</span>
                  </i>
                </button>
              </div>
              <div
                v-if="projectSubmitted && !$v.project.due_date.required"
                style="display: inline-block"
                class="invalid-feedback"
              >
                Target Completion Date is required
              </div>
            </div>
          </b-form-group>
        </b-form-row>

        <b-form-row>
          <b-form-group label="Stage" class="col-12">
            <b-form-select
              v-model="project.stage_id"
              :options="projectStageOptions"
              :class="{
                'is-invalid': projectSubmitted && $v.project.stage_id.$error,
              }"
            ></b-form-select>
            <div
              v-if="projectSubmitted && !$v.project.stage_id.required"
              class="invalid-feedback"
            >
              Stage field is required.
            </div>
          </b-form-group>
        </b-form-row>

        <b-form-row>
          <b-form-group label="Genre" class="col-12">
            <b-form-select
              v-model="project.genre_id"
              :options="genre_select"
              :class="{
                'is-invalid': projectSubmitted && $v.project.genre_id.$error,
              }"
            ></b-form-select>
            <div
              v-if="projectSubmitted && !$v.project.genre_id.required"
              class="invalid-feedback"
            >
              Genre field is required.
            </div>
          </b-form-group>
        </b-form-row>

        <b-form-row v-if="project.type === 'music'">
          <b-form-group class="col-12">
            <template slot="label">
              Artist / Group
              <a href="#" class="text-primary" @click="createNewBandGroup"
                ><i class="ion ion-md-add-circle"></i>
              </a>
            </template>
            
            <multiselect
              v-model="project.band_id"
              id="band_id"
              label="name"
              track-by="id"
              placeholder="Type to search"
              open-direction="bottom"
              :options="bandOptions"
              :multiple="false"
              :searchable="true"
              :loading="isBandLoading"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :options-limit="300"
              :max-height="600"
              :show-no-results="true"
              :hide-selected="true"
              @search-change="asyncBandFind"
              :class="{
                'is-invalid': $v.project.band_id.$error && $v.project.band_id.$dirty,
              }"
            >
              <template slot="clear"></template>
              <span slot="noResult">Band not found.</span>
            </multiselect>

            <div
              v-if="projectSubmitted && !$v.project.band_id.required"
              class="invalid-feedback"
            >
              Band is required
            </div>
          </b-form-group>
        </b-form-row>

        <b-form-row v-if="project.type !== 'music'">
          <b-form-group class="col-12">
            <template slot="label">
              Company
              <a href="#" class="text-primary" @click="createNewCompany"
                ><i class="ion ion-md-add-circle"></i>
              </a>
            </template>
            <!-- <b-form-select
              v-model="project.company_id"
              :options="companyOptions"
              :class="{
                'is-invalid': projectSubmitted && $v.project.company_id.$error,
              }"
            ></b-form-select> -->

             <multiselect
              v-model="project.company_id"
              id="company_id"
              label="name"
              track-by="id"
              placeholder="Type to search"
              open-direction="bottom"
              :options="companyOptions"
              :multiple="false"
              :searchable="true"
              :loading="isCompanyLoading"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :options-limit="300"
              :max-height="600"
              :show-no-results="true"
              :hide-selected="true"
              @search-change="asyncCompanyFind"
              :class="{
                'is-invalid': $v.project.company_id.$error && $v.project.company_id.$dirty,
              }"
            >
              <template slot="clear"></template>
              <span slot="noResult">Company not found.</span>
            </multiselect>
            <div
              v-if="projectSubmitted && !$v.project.company_id.required"
              class="invalid-feedback"
            >
              Company field is required.
            </div>
          </b-form-group>
        </b-form-row>

        <b-form-row>
          <b-form-group label="" class="col-sm-12">
            <template slot="label">
              Primary Contact
              <a href="#" class="text-primary" @click="createNewPrimaryContact"
                ><i class="ion ion-md-add-circle"></i>
              </a>
            </template>

            <multiselect
              v-model="project.primary_contact_id"
              :class="{
                'is-invalid':
                  projectSubmitted && $v.project.primary_contact_id.$error,
              }"
              id="primary_contact_ajax"
              label="full_name"
              track-by="id"
              :placeholder="contactTypePlaceholder"
              open-direction="bottom"
              :options="primary_contact_options"
              :multiple="false"
              :searchable="true"
              :loading="primary_contact_loading"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :max-width="200"
              :max-height="600"
              :show-no-results="false"
              :hide-selected="true"
              @select="selectPrimaryContact"
              @search-change="asyncContactFind"
            >
              <template slot="clear" slot-scope="props"> </template
              ><span slot="noResult">Contact not found.</span>
            </multiselect>
            <div
              v-if="projectSubmitted && !$v.project.primary_contact_id.required"
              style="display: inline-block"
              class="invalid-feedback"
            >
              Primary Contact field is required.
            </div>
          </b-form-group>
        </b-form-row>

        <b-form-row v-if="project.type == 'music'">
          <b-form-group label="Number of Songs" class="col-12">
            <b-input
              ref="number_of_songs"
              v-model="project.number_of_songs"
              :class="{
                'is-invalid':
                  projectSubmitted && $v.project.number_of_songs.$error,
              }"
            ></b-input>
            <div
              v-if="
                projectSubmitted && !$v.project.number_of_songs.mustBeNumeric
              "
              class="invalid-feedback"
            >
              Number of Songs field is required.
            </div>
          </b-form-group>
        </b-form-row>

        <b-form-row v-if="project.type !== 'music'">
          <b-form-group label="Number of Episodes" class="col-12">
            <b-input
              ref="number_of_episodes"
              v-model="project.number_of_episodes"
              :class="{
                'is-invalid':
                  projectSubmitted && $v.project.number_of_episodes.$error,
              }"
            ></b-input>
            <div
              v-if="projectSubmitted && !$v.project.number_of_episodes.required"
              class="invalid-feedback"
            >
              Number of Episodes field is required.
            </div>
          </b-form-group>
        </b-form-row>

        <b-form-row>
          <b-form-group label="Project Revenue Estimate" class="col-12">
            <b-input
              ref="project_revenue_estimate"
              v-model.lazy="project.project_revenue_estimate"
              :class="{
                'is-invalid':
                  projectSubmitted &&
                  $v.project.project_revenue_estimate.$error,
              }"
            ></b-input>
            <div
              v-if="
                projectSubmitted &&
                !$v.project.project_revenue_estimate.mustBeNumberDecimal
              "
              class="invalid-feedback"
            >
              Project Revenue Estimate field should be a valid number.
            </div>
          </b-form-group>
        </b-form-row>

        <b-form-row>
          <b-form-group label="Location of Session Files" class="col-12">
            <b-input
              ref="location_of_session_files"
              v-model="project.location_of_session_files"
            ></b-input>
          </b-form-group>
        </b-form-row>

        <b-form-row>
          <b-form-group label="% Project Complete" class="col-12">
            <b-input
              type="number"
              ref="project_completion"
              v-model="project.project_completion"
            ></b-input>
          </b-form-group>
        </b-form-row>

        <b-form-row v-if="editId == null">
          <b-form-group label="Project Notes" class="col-12">
            <quill-editor
              ref="project_white_board"
              v-model="project.white_board"
              :options="quillEditorOptions"
            />
          </b-form-group>
        </b-form-row>
      </b-form>

      <custom-field-form-template :data.sync="project" object="Project" />


      <template slot="modal-footer">
        <b-button :disabled="loading" variant="default" @click="cancel()">
          Cancel
        </b-button>
        <b-button :disabled="loading" variant="primary" @click="handleSubmit()">
          Save
        </b-button>
      </template>
    </b-modal>
    <contact-form
      ref="primaryContactFormModal"
      @on-save="primaryContactAdded"
    />
    <band-form ref="bandFormModal" @on-save="bandAdded" />
    <company-form ref="companyFormModal" @on-save="companyAdded" />
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="@/vendor/libs/vue-quill-editor/typography.scss" lang="scss"></style>
<style src="@/vendor/libs/vue-quill-editor/editor.scss" lang="scss"></style>

<script>
import Vue from "vue";

import Vuelidate from "vuelidate";
import Toasted from "vue-toasted";
import {
  required,
  helpers,
  requiredIf,
  maxLength,
} from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import BandForm from "@/components/bands/BandForm";
import ContactForm from "@/components/contacts/ContactForm";
import CompanyForm from "@/components/companies/CompanyForm";
import CustomFieldFormTemplate from "../customField/CustomFieldFormTemplate.vue";
















Vue.use(Vuelidate);
Vue.use(Toasted);

const mustBeNumeric = (value) =>
  !helpers.req(value) || /^-{0,1}\d+$/.test(value);
const mustBeNumberDecimal = (value) =>
  !helpers.req(value) || /^\d+(\.\d{1,2})?$/.test(value);

const initialData = {
  project_code: "",
  name: "",
  stage_id: "",
  start_date: "",
  due_date: "",
  genre_id: "",
  project_revenue_estimate: "",
  number_of_songs: 0,
  number_of_episodes: 0,
  white_board: "",
  band_id: "",
  primary_contact_id: "",
  company_id: "",
  project_completion: "",
  location_of_session_files: "",
  type: "music",
  custom_fields: {}
};

export default {
  name: "ProjectForm",
  props: {
    editId: {
      type: Number,
      default: null,
    },
    redirectToDetail: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    CustomFieldFormTemplate,
    ContactForm,
    BandForm,
    Multiselect,
    CompanyForm,
    quillEditor: () =>
      import("vue-quill-editor/dist/vue-quill-editor")
        .then((m) => m.quillEditor)
        .catch(() => {}),
  },

  computed: {
    formTitle() {
      if (this.editId !== null) {
        return `Edit Project ${this.project.name}`;
      } else {
        return "Create Project";
      }
    },
    contactTypePlaceholder() {
      let placeholder = this.primary_contact_id
        ? this.primary_contact_id.full_name
        : `Type to search`;
      return placeholder;
    },
  },

  created() {
    if (window.location.href.includes("spotify")) {
      this.isSpotify = true;
    }
  },

  data: () => ({
    isSpotify: false,
    genre_select: [],
    showModal: false,
    project: { ...initialData },
    primary_contact_options: [],
    primary_contact_loading: false,
    bandOptions: [],
    isBandLoading: false,
    datePickerConfig: {
      wrap: true, // set wrap to true only when using 'input-group'
      altFormat: window.toFlatPickrDatetime(localStorage.getItem('php_date_format')),
      altInput: true,
      dateFormat: window.datePickerSaveFormat,
      enableTime: false,
      static: false,
      //locale: Hindi, // locale for this instance only
    },
    projectSubmitted: false,
    loading: false,
    projectTypeConfig: {
      music: true,
      podcast: false,
      audioBook: false,
      voiceOver: false,
      postProduction: false,
      other: false,
    },
    projectTypeOptions: [],
    isCompanyLoading: false,
    companyOptions: [],
    projectStageOptions: [{
      value: '',
      text: 'Choose Project Stage'
    }],

    quillEditorOptions: {
      modules: {
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }, { size: [] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ script: "sub" }, { script: "super" }],
          ["blockquote", "code-block"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          [{ direction: "rtl" }, { align: [] }],
          ["link", "image", "video"],
          ["clean"],
        ],
      },
    },
  }),

  watch: {
    "project.band_id"(newVal, oldVal) {
      if (newVal != oldVal && newVal && newVal.id) {
        this.loadBand(newVal.id);
      }
    },
    "project.company_id"(newVal, oldVal) {
      if (newVal != oldVal && newVal && newVal.id) {
        this.loadCompany(newVal.id);
      }
    },
  },

  methods: {
    async loadProjectStageOptions(){
       try {
        const stageOptionResponse = await window.axios.get('/api/picklists/Project Stages');
        console.log(stageOptionResponse.data.data.options);
        if(stageOptionResponse.data && stageOptionResponse.data.data && stageOptionResponse.data.data.options){
          const options = stageOptionResponse.data.data.options.map((option) => {
            return {
              text: option.name,
              value: option.id
            }
          });
          this.projectStageOptions = [...this.projectStageOptions, ...options]
        }
      } catch (error) {
        console.log(error);
      }
    },
    async loadContactOptions(){
      try {
        const contactResponse = await window.axios.post(
          "/api/contacts/search-select"
        );
        const primaryContactOptions = contactResponse.data.map((contact) => {
          return {
            id: contact.id,
            full_name: contact.full_name,
          };
        });

        this.primary_contact_options = [
          ...this.primary_contact_options,
          ...primaryContactOptions,
        ];
        
      } catch (error) {
        console.log(error);
      }
    },
    async loadBandOptions(){
      try {

        this.isBandLoading = true;
        const queryParams = {
          'fields' : 'id,name'
        }
        const queryString = Object.keys(queryParams).map(function(key) {
            return key + '=' + queryParams[key]
        }).join('&');
        const response = await window.axios.get(`/api/v2/bands/options?${queryString}`);
        this.bandOptions = response.data;
        this.isBandLoading = false;
        
      } catch (error) {
        this.isBandLoading = false;
        console.log(error);
      }
    },

    asyncBandFind: _.debounce(async function (query) {
      try {
        this.isBandLoading = true;
        const response = await window.axios.post("/api/bands/search-select", {
          search_string: query,
        });

        this.bandOptions = response.data;
        this.isBandLoading = false;
      } catch (error) {
        this.isBandLoading = false;
        console.log(error);
      }
    }, 500),

    async createNewCompany() {
      this.$refs.companyFormModal.show();
    },
    async loadCompanySettings() {
      const settingResponse = await window.axios.get("/api/settings");

      const projectTypeSetting = settingResponse.data.find((setting) => {
        return setting.name == "company_project_types";
      });

      if (projectTypeSetting) {
        this.projectTypeConfig = JSON.parse(projectTypeSetting.value);

        const musicOption = this.projectTypeOptions.find((option) => {
          return option.value == "music";
        });
        if (this.projectTypeConfig.music && musicOption === undefined) {
          this.projectTypeOptions.push({ value: "music", text: "Music" });
        }

        const podcastOption = this.projectTypeOptions.find((option) => {
          return option.value == "podcast";
        });
        if (this.projectTypeConfig.podcast && podcastOption === undefined) {
          this.projectTypeOptions.push({ value: "podcast", text: "Podcast" });
        }

        const audiobookOption = this.projectTypeOptions.find((option) => {
          return option.value == "audio-book";
        });
        if (this.projectTypeConfig.audioBook && audiobookOption === undefined) {
          this.projectTypeOptions.push({
            value: "audio-book",
            text: "Audiobook",
          });
        }

        const voiceOverOption = this.projectTypeOptions.find((option) => {
          return option.value == "voiceover";
        });
        if (this.projectTypeConfig.voiceOver && voiceOverOption === undefined) {
          this.projectTypeOptions.push({
            value: "voiceover",
            text: "Voiceover",
          });
        }

        const postProductionOption = this.projectTypeOptions.find((option) => {
          return option.value == "post-production";
        });
        if (
          this.projectTypeConfig.postProduction &&
          postProductionOption === undefined
        ) {
          this.projectTypeOptions.push({
            value: "post-production",
            text: "Post-Production",
          });
        }

        const otherOption = this.projectTypeOptions.find((option) => {
          return option.value == "other";
        });
        if (this.projectTypeConfig.other && otherOption === undefined) {
          this.projectTypeOptions.push({ value: "other", text: "Other" });
        }

        if (this.projectTypeOptions.length == 1) {
          this.project.type = this.projectTypeOptions[0].value;
        }
      }
    },

    async loadCompanies() {
      const companyResponse = await window.axios.get(
        `/api/companies/option-list`
      );
      if (companyResponse.data.length > 0) {
        const options = companyResponse.data.map((company) => {
          company.text = company.name;
          company.value = company.id;
          return company;
        });

        this.companyOptions = [...this.companyOptions, ...options];
      }
    },

     asyncCompanyFind: _.debounce(async function (query) {
      try {
        this.isCompanyLoading = true;
        const response = await window.axios.post(
          "/api/companies/search-select",
          {
            search_string: query,
          }
        );
        this.companyOptions = response.data;
        this.isCompanyLoading = false;
      } catch (error) {
        this.isCompanyLoading = false;
        console.log(error);
      }
    }),

    async loadBand(id) {
      const bandResponse = await window.axios.get(`/api/bands/${id}`);
      if (
        bandResponse.data.primary_contact &&
        bandResponse.data.primary_contact.length > 0
      ) {
        this.project.primary_contact_id = bandResponse.data.primary_contact[0];
      }
    },
    async loadCompany(id) {
      const companyResponse = await window.axios.get(`/api/companies/${id}`);
      this.project.primary_contact_id = companyResponse.data.primary_contact;
    },
    async loadGenres() {
      const genreResponse = await window.axios.get("/api/picklists/Genres");
      const genreOptions = genreResponse.data.data.options.map((genre) => {
        return {
          value: genre.id,
          text: genre.name,
        };
      });
      this.genre_select = [
        ...[{ value: "", text: "Choose Genre" }],
        ...genreOptions,
      ];
    },
    focusMyElement() {},

    selectPrimaryContact(contact) {
      this.project.primary_contact_id = contact;
    },

    cancel() {
      this.clearForm();
      this.hide();
    },

    //when ProjectForm modal shown
    async onShow() {
      try {
        this.loadGenres();
        this.loadCompanySettings();
        this.loadCompanies();
        this.loadBandOptions();
        this.loadContactOptions();
        this.loadProjectStageOptions();

        if (this.editId) {
          const projectResponse = await window.axios.get(
            `/api/projects/${this.editId}`
          );
          this.project = projectResponse.data;
          if (this.project.type == null) {
            this.project.type = "music";
          }
          this.project.primary_contact_id = this.project.primary_contact;
          this.project.company_id = this.project.company;
          this.project.band_id = this.project.band;
        }

      } catch (error) {
        console.log(error);
      }
    },

    //when ProjectForm modal hidden
    onHidden() {
      this.clearForm();
      this.hide();
    },

    show() {
      this.showModal = true;
    },

    asyncContactFind: _.debounce(async function (query) {
      try {
        const contactResponse = await window.axios.post(
          "/api/contacts/search-select",
          {
            search_string: query,
          }
        );
        this.primary_contact_options = [];
        const primaryContactOptions = contactResponse.data.map((contact) => {
          return {
            id: contact.id,
            full_name: contact.full_name,
          };
        });

        this.primary_contact_options = primaryContactOptions;
      } catch (error) {
        console.log(error);
      }
    }, 500),

    async handleSubmit() {
      this.$store.state.projectListUpdate = true;
      this.projectSubmitted = true;
      if (this.project.project_revenue_estimate) {
        this.project.project_revenue_estimate = parseFloat(
          this.project.project_revenue_estimate
        ).toFixed(2);
      }

      this.$v.$touch();
      var isValid = !this.$v.$invalid;
      if (isValid) {
        
        const selectedStage = this.projectStageOptions.find(option => {
          option.value == this.project.stage_id;
        });
        if(selectedStage && selectedStage.name == 'Delivered'){
          this.project.project_completion = 100;
        }

        const projectData = { ...this.project };
        if (projectData.primary_contact_id.id) {
          projectData.primary_contact_id = projectData.primary_contact_id.id;
        }

        if(projectData.company_id && projectData.company_id.id){
          projectData.company_id = projectData.company_id.id;
        }

        if(projectData.band_id && projectData.band_id.id){
          projectData.band_id = projectData.band_id.id;
        }

        this.loading = true;
        if (this.editId !== null) {
          try {
            const projectResponse = await window.axios.put(
              `/api/projects/${this.editId}`,
              projectData
            );
            this.projectSubmitted = false;
            this.$snotify.success("Project data updated.", "Success!");
            this.$emit("on-save", projectResponse.data, isValid);
            this.loading = false;
            this.hide(projectResponse.data.id);
          } catch (error) {
            console.log(error);
            this.loading = false;
            this.projectSubmitted = false;
            this.errors = error.response.data.errors;
            this.$snotify.error(
              "There was a problem updating Company",
              "Error!"
            );
          }
        } else {
          try {
            const projectResponse = await window.axios.post(
              `/api/projects`,
              projectData
            );
            this.projectSubmitted = false;
            this.$snotify.success("New Project Created.", "Success!");
            this.$emit("on-save", projectResponse.data, isValid);
            this.loading = false;
            this.hide(projectResponse.data.id);
          } catch (error) {
            console.log(error);
            this.loading = false;
            this.projectSubmitted = false;
            this.errors = error.response.data.errors;
            this.$snotify.error(
              "There was a problem creating Company",
              "Error!"
            );
          }
        }
      }
    },

    createNewPrimaryContact() {
      this.$refs.primaryContactFormModal.show();
    },

    primaryContactAdded(contact) {
      this.project.primary_contact_id = contact;
    },

    companyAdded(company) {
      this.companyOptions.push({
        text: company.name,
        value: company.id,
      });

      this.project.company_id = company.id;
      if (company.primary_contact) {
        this.project.primary_contact_id = company.primary_contact;
      }
    },

    createNewBandGroup() {
      this.$refs.bandFormModal.show();
    },

    bandAdded(band) {
      this.bandOptions.push({
        text: band.name,
        value: band.id,
      });
      this.project.band_id = band;
    },

    limitText(count) {
      return `and ${count} other contacts`;
    },

    clearForm() {
      this.project = { ...initialData };
      this.bandOptions = [{ text: "Choose Artist / Group", value: "" }];
      this.primary_contact = null;
      this.primary_contact_options = [];
      this.primary_contact_loading = false;
      this.$v.$reset();
    },

    hide(id) {
      this.showModal = false;
      if ((id != null || id != undefined) && this.redirectToDetail) {
        this.$router.push(`/projects/${id}`);
      }
    },
  },

  validations: {
    project: {
      name: { required },
      project_code: {
        required: requiredIf(function () {
          if (this.isSpotify) {
            return true;
          } else {
            return false;
          }
        }),
        maxLength: maxLength(6),
      },
      stage_id: { required },
      start_date: { required },
      due_date: { required },
      genre_id: { required },
      primary_contact_id: { required },
      project_revenue_estimate: { mustBeNumberDecimal },
      number_of_songs: {
        required: requiredIf(function () {
          if (this.project.type == "music") {
            return true;
          } else {
            return false;
          }
        }),
      },
      number_of_episodes: {
        required: requiredIf(function () {
          if (this.project.type != "music") {
            return true;
          } else {
            return false;
          }
        }),
      },
      band_id: {
        required: requiredIf(function () {
          if (this.project.type == "music") {
            return true;
          } else {
            return false;
          }
        }),
      },
      company_id: {
        required: requiredIf(function () {
          if (this.project.type !== "music") {
            return true;
          } else {
            return false;
          }
        }),
      },
    },
  },
};
</script>
<style scoped>
.project-type-radio-label {
  font-size: 80%;
}
.project-type-radio-icon {
  font-size: 1.5rem
}

.project-type-radio {
  display: flex;
  flex-direction: column;
  border-radius: 0;
}

.project-type-radio-item {
  margin-top: 5px !important;
  border-radius: 5px !important;
  background: #daeff4;
  border-color: #daeff4;
  color: #4e5155;
}
.project-type-radio-item.active {
  background: #46b1c9;
  color: white;
}

@media (min-width: 768px) {
  .project-type-radio {
    display: flex;
    flex-direction: row;
  }

  .project-type-radio {
    margin: -8px;
  }

  .project-type-radio > * {
    margin: 8px;
  }

  .project-type-radio-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-basis: 100px;
    flex-grow: 0;
    flex-shrink: 0;
    height: 100px;
    border-radius: 12px !important;
  }
}
</style>