<script>
    import ContactForm from '@/components/contacts/ContactForm'
    export default {
        extends : ContactForm,
        name: "SharedPrimaryContactForm",
        methods: {
            showModal(sourceId){
                this.sharedModalOptions.sourceId = sourceId;
                this.loadCompanyOptions();
                this.loadBandOptions();
                this.loadCountryOptions();

                this.show_modal = true;
            }
        }
       
    }
</script>