import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import NProgress from 'nprogress'

import globals from '@/globals'

Vue.use(Router);

Vue.use(Meta);

// Layouts
import dashboardsRoutes from './dashboards'
import authenticationRoutes from './authentication'
import userRoutes from './user'
import profileRoutes from './profile'
import settingsRoutes from './settings'
import leadRoutes from './leads'
import taskRoutes from './tasks'
import eventRoutes from './events'
import contactRoutes from './contacts'
import companyRoutes from './companies'
import picklistRoutes from './picklists'
import equipmentCategoryRoutes from './equipmentCategories'
import bandGroupRoutes from './bandGroups'
import projectRoutes from './projects'
import roomRoutes from './rooms'
import calendarRoutes from './bookings'
import quoteRoutes from './quotes'
import invoiceRoutes from './invoices'
import notificationRoutes from './notifications'
import reportRoutes from './reports'
import exportRoutes from './exports'
import importRoutes from './imports'
import supportAccess from './supportaccess'
import reviewer from './reviewer'
import reviewerRevoked from './reviewerRevoked'
import reportBuilderRoutes from './reportbuilders'
import equipmentRoutes from './equipments'
import customFieldRoutes from './customField'
import couponRoutes from './coupons'
import pricelistRoutes from './pricelists'

const ROUTES = [
  // Default route
  { path: '', redirect: '/dashboard' },
  { path: '/home', redirect: '/dashboard' },
]
  .concat(dashboardsRoutes)
  .concat(authenticationRoutes)
  .concat(userRoutes)
  .concat(profileRoutes)
  .concat(settingsRoutes)
  .concat(leadRoutes)
  .concat(taskRoutes)
  .concat(eventRoutes)
  .concat(contactRoutes)
  .concat(companyRoutes)
  .concat(picklistRoutes)
  .concat(equipmentCategoryRoutes)
  .concat(bandGroupRoutes)
  .concat(projectRoutes)
  .concat(roomRoutes)
  .concat(calendarRoutes)
  .concat(quoteRoutes)
  .concat(invoiceRoutes)
  .concat(notificationRoutes)
  .concat(reportRoutes)
  .concat(exportRoutes)
  .concat(importRoutes)
  .concat(supportAccess)
  .concat(reviewer)
  .concat(reviewerRevoked)
  .concat(reportBuilderRoutes)
  .concat(equipmentRoutes)
  .concat(customFieldRoutes)
  .concat(couponRoutes)
  .concat(pricelistRoutes)

const router = new Router({
  base: '/',
  mode: 'history',
  routes: ROUTES,
  scrollBehavior() {
    window.scrollTo(0, 0);
  }
})

router.afterEach(() => {
  // Remove initial splash screen
  const splashScreen = document.querySelector('.app-splash-screen')
  if (splashScreen) {
    splashScreen.style.opacity = 0
    setTimeout(() => splashScreen && splashScreen.parentNode.removeChild(splashScreen), 300)
  }

  // On small screens collapse sidenav
  if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0)

  NProgress.done()
})

router.beforeEach(async (to, from, next) => {
  NProgress.start()

  let bd = to.query.bd
  let sk = to.query.secret_key
  let oktaToken = to.query.okta_token;
  let entraIdToken = to.query.entraid_token;

  //backboor related logic
  if (bd) {

    window.axios.defaults.headers.common['Authorization'] = `Bearer ${atob(bd)}`
    localStorage.setItem('accessToken', atob(bd));

    try {

      const userOptions = await window.axios.get('/api/user-options');
      localStorage.setItem('options', JSON.stringify(userOptions.data.options));

      const userRolePermissions = await window.axios.get('/api/user-getRolesAndPermissions');
      localStorage.setItem('permissions', JSON.stringify(userRolePermissions.data.permissions))
      localStorage.setItem('roles', JSON.stringify(userRolePermissions.data.roles))

      window.ability.update(userRolePermissions.data.permissions);

      let query = Object.assign({}, next.query);
      delete query.bd;
      router.replace({ query });
      next();
      
    } catch (error) {
      console.log(error);
      router.push('/login');
    }

  }

  else if (entraIdToken) {
    
    window.axios.defaults.headers.common['Authorization'] = `Bearer ${atob(entraIdToken)}`
    localStorage.setItem('accessToken', atob(entraIdToken));

    const userOptions = await window.axios.get('/api/user-options');
    localStorage.setItem('options', JSON.stringify(userOptions.data.options));

    const userRolePermissions = await window.axios.get('/api/user-getRolesAndPermissions');
    localStorage.setItem('permissions', JSON.stringify(userRolePermissions.data.permissions))
    localStorage.setItem('roles', JSON.stringify(userRolePermissions.data.roles))

    window.ability.update(userRolePermissions.data.permissions);

    let query = Object.assign({}, next.query);
    delete query.entraid_token;
    router.replace({ query });
    next();
  }

  else if (oktaToken) {
    
    window.axios.defaults.headers.common['Authorization'] = `Bearer ${atob(oktaToken)}`
    localStorage.setItem('accessToken', atob(oktaToken));

    try {

      const userOptions = await window.axios.get('/api/user-options');
      localStorage.setItem('options', JSON.stringify(userOptions.data.options));

      const userRolePermissions = await window.axios.get('/api/user-getRolesAndPermissions');
      localStorage.setItem('permissions', JSON.stringify(userRolePermissions.data.permissions))
      localStorage.setItem('roles', JSON.stringify(userRolePermissions.data.roles))

      window.ability.update(userRolePermissions.data.permissions);

      let query = Object.assign({}, next.query);
      delete query.okta_token;
      router.replace({ query });

      next();

    } catch (error) {
      console.log(error);
      router.push('/login');
    }
  }
  
  //review portal related logic, if the have secret_key in their url
  else if (sk) {
    try {
      let info = await window.axios.get('/api/review-portal/general-info?secret_key=' + sk)
      localStorage.setItem('rp_company_name', info.data.settings.company_name)
      localStorage.setItem('rp_company_email', info.data.settings.company_email)
      localStorage.setItem('rp_logo_path', info.data.settings.logo_path)
      localStorage.setItem('rp_secret_key', sk)
      localStorage.setItem('rp_project_name', info.data.reviewer.project.name)
      localStorage.setItem('rp_reviewer_id', info.data.reviewer.id)
      localStorage.setItem('rp_reviewer_name', info.data.reviewer.name)
      localStorage.setItem('rp_project_id', info.data.reviewer.project_id)

      let dateTimeFormat = 'm/d/Y h:ia';
      if(info.data.settings.company_datetime_format){
        dateTimeFormat = info.data.settings.company_datetime_format;
      }

      let dateFormat = 'm/d/Y'
      if(dateTimeFormat == 'Y/m/d h:ia' || dateTimeFormat == 'Y/m/d H:i'){
        dateFormat = 'Y/m/d';
      } 
      else if(dateTimeFormat == 'd/m/Y h:ia' || dateTimeFormat == 'd/m/Y H:i'){
        dateFormat = 'd/m/Y';
      }

      localStorage.setItem('rp_php_date_format', dateFormat);
      localStorage.setItem('rp_php_datetime_format', dateTimeFormat);

      var momentDateTimeFormat = window.toMomentDateTime(dateTimeFormat);
      localStorage.setItem('rp_datetime_format', momentDateTimeFormat);
      localStorage.setItem('rp_date_format', window.splitDateAndTimeFormat(momentDateTimeFormat)['date']);
    }
    catch (e) {
      router.push({
        name: 'review-portal-revoked'
      });
    }
  }
  else {
    window.axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`
  }

  if (to.fullPath.includes('review-portal')) {
    next();
  }
  else if(to.fullPath.includes('okta-sso')) {
    next();
  }
  else if (to.fullPath !== "/login" && to.fullPath !== "/password-reset" && to.fullPath.indexOf("/password-change") && to.fullPath !== '/okta-login') {
    await window.axios.get('/api/me').then(response => {
      if(response.data['user']['status'] == 'disabled'){
        window.ability.update([]);
        localStorage.clear();
        router.push('/login');
      }

      localStorage.setItem('user_id', response.data['user'].id)
      localStorage.setItem('email', response.data['user'].email)
      localStorage.setItem('first_name', response.data['user'].first_name)
      localStorage.setItem('last_name', response.data['user'].last_name)
      localStorage.setItem('photo_path', response.data['user'].photo_path)
      localStorage.setItem('timezone', response.data['user'].timezone)
      localStorage.setItem('settings', JSON.stringify(response.data['settings']));
      
      let dateTimeFormat = 'm/d/Y h:ia';
      if(response.data['settings']['company_datetime_format']){
        dateTimeFormat = response.data['settings']['company_datetime_format'];
      }

      let dateFormat = 'm/d/Y'
      if(dateTimeFormat == 'Y/m/d h:ia' || dateTimeFormat == 'Y/m/d H:i'){
        dateFormat = 'Y/m/d';
      } 
      else if(dateTimeFormat == 'd/m/Y h:ia' || dateTimeFormat == 'd/m/Y H:i'){
        dateFormat = 'd/m/Y';
      }

      localStorage.setItem('php_date_format', dateFormat);
      localStorage.setItem('php_datetime_format', dateTimeFormat);

      var momentDateTimeFormat = window.toMomentDateTime(dateTimeFormat);
      localStorage.setItem('datetime_format', momentDateTimeFormat);
      localStorage.setItem('date_format', window.splitDateAndTimeFormat(momentDateTimeFormat)['date']);


      localStorage.setItem('logo_path', `${response.data['settings']['logo_path']}`)
      localStorage.setItem('website_uuid', response.data['website_uuid'])
      localStorage.setItem('website_p', response.data['website_p'])
      localStorage.setItem('website_a', response.data['website_a'])
      next()
    }).catch(error => {
      console.log(error);
      const intendedUrl = localStorage.setItem('intended_url',to.fullPath);
      if(intendedUrl){
        localStorage.removeItem('intended_url');
        router.push(intendedUrl);
      }
      else {
        router.push('/login');
      }
    })
  }

  if (window.ability.rules.length <= 0) {
    let permissions = JSON.parse(localStorage.getItem('permissions'))
    window.ability.update(permissions)
  }
  // Set loading state
  document.body.classList.add('app-loading')

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10)
})

export default router
